import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import "../css/Style.css";
import api from "../services/Api";
import Modal from 'react-modal';

const Cadastros = () => {
  const [entidades, setEntidades] = useState([]);
  const [filteredEntidades, setFilteredEntidades] = useState([]);
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("ativo");
  const [modalIsOpenConfirma, setModalIsOpenConfirma] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);
  const navigate = useNavigate();




  useEffect(() => {
    const fetchEntidades = async () => {
      try {
        const response = await api.get('/entidades');
        setEntidades(response.data);
        setFilteredEntidades(response.data);
      } catch (error) {
        console.error("Erro ao buscar entidades:", error);
      }
    };
    fetchEntidades();
  }, []);




  useEffect(() => {
    let filtered = entidades;

    if (filterStatus === "ativo") {
      filtered = filtered.filter((entidade) => entidade.ativo === 1);
    } else if (filterStatus === "desativado") {
      filtered = filtered.filter((entidade) => entidade.ativo === 0);
    }

    if (search) {
      filtered = filtered.filter((entidade) =>
        entidade.descricao.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredEntidades(filtered);
  }, [search, filterStatus, entidades]);

  

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };



  const handleStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };


  const handleDelete = (id) => {
    setEntityToDelete(id);
    setModalIsOpenConfirma(true);
  };

  const closeModal = () => {
    setModalIsOpenConfirma(false);
  };

  const confirmDelete = async () => {
    try {
      await api.delete('/entidades',{data: { id: entityToDelete }});
      const updatedEntidades = entidades.filter((entidade) => entidade.id !== entityToDelete);
      setEntidades(updatedEntidades);
      setFilteredEntidades(updatedEntidades);
    } catch (error) {
      console.error("Erro ao excluir entidade:", error);
    } finally {
      setModalIsOpenConfirma(false);
      setEntityToDelete(null);
      setModalIsOpen(true);
      setTimeout(() => {
        setModalIsOpen(false);
      }, 2000);
    }
  };

 



  const handleEdit = (entidade) => {
    navigate(`/entidades?id=${entidade.id}`);
  };



  
  return (
    <div className="container">
      <div className="title">Entidades</div>
      <div className="form-group">
        <div className="col">
        <input
          type="text"
          placeholder="Pesquisa"
          value={search}
          onChange={handleSearch}
        />
        </div>
        <div className="col">
        <select className="select" onChange={handleStatusChange} value={filterStatus}>
          <option value="todos">Todos</option>
          <option value="ativo">Ativo</option>
          <option value="desativado">Desativado</option>
        </select>
        </div>
        <div className="col">
        <Link to="/entidades">
          <button type="button" className="button">Nova Entidade</button>
        </Link>
        </div>
      </div>
      <div className="tabela-title">
        <p>Lista de Entidades</p>
      </div>
      <div className="col">
      <div className="tabela">
        <ul style={{height:400}}>
          {filteredEntidades.length === 0 ? (
            <p>Nenhuma entidade encontrada.</p>
          ) : (
            filteredEntidades.map((entidade, index) => (
              <li key={index}>
                <span onClick={() => handleEdit(entidade)}>{entidade.descricao}</span>
                <MdDelete className="icon" onClick={() => handleDelete(entidade.id)} />
              </li>
            ))
          )}
        </ul>
        </div>
      </div>  
            <Modal
            isOpen={modalIsOpenConfirma}
            onRequestClose={closeModal}
            contentLabel="Exclusão de Entidade"
            overlayClassName="ReactModal__Overlay"  // Classe CSS do overlay
          
          >
            <div className="container-modal">
            <h6>Tem certeza que deseja excluir esta entidade?</h6>
            <div className="modal-buttons">
              <button onClick={confirmDelete}>Confirmar</button>
              <button onClick={closeModal}>Cancelar</button>
            </div>
            </div>
          </Modal>
          <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Confirmação de Sucesso"
        style={{
          content: {
            background:'rgba(2, 129, 2, 0.532)',
            color: 'white',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <h2>Excluido com Sucesso!</h2>
      </Modal>
      

      


    </div>
  );
};

export default Cadastros;
